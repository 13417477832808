/* purgecss start ignore */
@tailwind base;
@tailwind components;
@import "swiper/swiper-bundle.css";
/* @import "swiper/module/hash-navigation.css"; */
/* purgecss end ignore */

html {
  @apply bg-gray-600;
  @apply font-mono;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

.swiper-container {
  /* min-height: 100vh; */
  /* min-height: -webkit-fill-available; */
  height: 100%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  margin: 0;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.loader,
.loader:before,
.loader:after {
  background: #0d9488;
  animation: load1 1s infinite ease-in-out;
  width: 0.75rem;
  height: 1.5rem;
}
.loader {
  margin: auto;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1em;
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 #0d9488;
    height: 1.5em;
  }
  40% {
    box-shadow: 0 -0.5rem #0d9488;
    height: 2em;
  }
}

@tailwind utilities;
